<template>

  <v-container id="orderCreatePage2" class="h-100">

    <div class="remaining-space">

      <div class="py-3 mb-5">
        <div class="top-text my-3">
          <h1 class="title is-1 thin black--text">Что</h1>
          <h1 class="title is-1 black--text">везём?</h1>
        </div>
      </div>

      <v-form ref="form_new_order" v-model="validate_new_order" lazy-validation>

        <b-field
          v-show="dontHaveThisModel"
          label="Напишите название вашего транспорта"
          expanded>
          <v-text-field
            v-model="descriptionOfYourTransport"
            outlined
            required
          ></v-text-field>
        </b-field>

        <v-autocomplete
          label="Марка машины"
          v-show="!dontHaveThisModel"
          v-model="order.car_mark"
          :items="car_marks"
          item-text="mark_name"
          item-value="id_mark"
          variant="outlined"
          :no-filter="true"
          @change="selectMark"
          @update:search-input="getCarMarks"
        >
          <template #no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Марка не найдена</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #empty>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Марка не найдена</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

        </v-autocomplete>


        <v-autocomplete
          :disabled="order.car_mark == null"
          label="Модель машины"
          v-show="!dontHaveThisModel"
          v-model="order.car_model"
          :items="car_models"
          item-text="name"
          item-value="id_model"
          variant="outlined"
          :no-filter="true"
          @update:search-input="getCarModels"
        >
          <template #no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Модель не найдена</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #empty>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Модель не найдена</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

        </v-autocomplete>

        <b-field expanded>
          <b-field expanded class="mb-0">
            <v-checkbox
              @click="clearMarkAndModel"
              label="Нет подходящей марки или модели"
              v-model="dontHaveThisModel">
            </v-checkbox>
          </b-field>
        </b-field>


        <b-field label="Номер транспорта" expanded>
          <v-text-field
            :placeholder="numberDisabled ? '' : 'А564АА159'"
            v-model="order.number_vehicle"
            :rules="numberDisabled ? [] : number_vehicle_rules"
            :disabled="numberDisabled"
            outlined
            required
          ></v-text-field>
        </b-field>

        <b-field label="Новая машина?" expanded>
          <b-field expanded class="mb-0">
            <v-checkbox
              label="У меня нет номера"
              @click="clearNumber"
              v-model="dontHaveANumber">
            </v-checkbox>
          </b-field>
        </b-field>

        <b-field label="Комментарий">
          <v-textarea
            v-model="order.comment"
            outlined
            required
          ></v-textarea>
        </b-field>
      </v-form>

    </div>

    <div class="pa-3 pb-0" style="text-align: center;">
        <p class="buttons mb-0" style="display: flex; justify-content: space-between;">
            <button class="button is-light is-medium" @click="$router.push('/order/create/1')">
                <span>Назад</span>
            </button>

            <button class="button is-light is-medium" @click="submitForm">
                <span>Далее</span>
                <span class="icon is-medium">
                    <i class="fas fa-chevron-right"></i>
                </span>
            </button>
        </p>
    </div>

  </v-container>

</template>

<script>

export default {
  name: 'OrderCreating2',
  data() {
    return {
      validate_new_order: false,
      numberDisabled: false,
      descriptionOfYourTransport: '',
    };
  },
  created() {
    if (this.$store.getters.BLOCK_TYPE == null) {
      this.$router.push('/main');
    } else if (this.$store.getters.ADDRESS_FROM == null || this.$store.getters.ADDRESS_TO == null
      || this.$store.getters.ADDRESS_FROM === '' || this.$store.getters.ADDRESS_TO === ''
    ) {
      this.$router.push('/order/create/1');
    }

    this.descriptionOfYourTransport = '';
  },
  computed: {
    number_vehicle_rules() {
      return this.$store.getters.NUMBER_VEHICLE_RULES;
    },
    car_marks() {
      return this.$store.getters.CAR_MARKS;
    },
    car_models() {
      return this.$store.getters.CAR_MODELS;
    },
    order: {
      get() {
        return this.$store.getters.ORDER;
      },
      set(value) {
        this.$store.commit('SET_ORDER', value);
      },
    },
    dontHaveANumber: {
      get() {
        return this.order.dont_have_a_number;
      },
      set(value) {
        this.order.dont_have_a_number = value;
      },
    },
    dontHaveThisModel: {
      get() {
        return this.order.dont_have_this_model;
      },
      set(value) {
        this.order.dont_have_this_model = value;
      },
    }
  },
  methods: {
    clearMarkAndModel() {
      if (this.dontHaveThisModel) {
        this.order.car_mark = null;
        this.order.car_model = null;
      }
    },
    clearNumber() {
      if (this.dontHaveANumber) {
        this.order.number_vehicle = "";
        this.numberDisabled = true;
      } else {
        this.numberDisabled = false;
      }
    },
    getCarMarks(text) {
      if (text !== null && text !== '') {
        const query_body = {
          query: text,
        };

        this.$store.dispatch('GET_CAR_MARKS', query_body)
          .catch(() => {
            this.$buefy.toast.open({
              message: 'Ошибка загрузки машин',
              type: 'is-warning',
            });
          });
      }
    },
    selectMark() {
      this.getCarModels('');
    },
    getCarModels(query) {
      if (this.order.car_mark != null) {
        const query_body = {
          query: query,
          car_mark: this.order.car_mark,
        };

        this.$store.dispatch('GET_CAR_MODELS', query_body)
          .catch(() => {
            this.$buefy.toast.open({
              message: 'Ошибка загрузки моделей',
              type: 'is-warning',
            });
          });
      }
    },
    submitForm() {
      if (this.$refs.form_new_order.validate()) {
        if (this.$store.getters.BLOCK_TYPE == null
          || this.$store.getters.ADDRESS_FROM == null
          || this.$store.getters.ADDRESS_FROM === ''
          || this.$store.getters.ADDRESS_TO == null
          || this.$store.getters.ADDRESS_TO === ''
        ) {
          this.$buefy.toast.open({
            message: 'Произошла ошибка',
            type: 'is-warning',
          });
          this.$router.push('/main');
        }

        if (
          (this.order.car_model == null
          || this.order.car_model === '') && !this.dontHaveThisModel
        ) {
          this.$buefy.toast.open({
            message: 'Заполните модель машины',
            type: 'is-warning',
          });
        } else {
          let copy_of_order_comment = this.order.comment;
          if (this.dontHaveThisModel && this.descriptionOfYourTransport && this.descriptionOfYourTransport !== '') {
            copy_of_order_comment = this.order.comment + '\nИнформация о транспорте: ' + this.descriptionOfYourTransport;
          }

          const newOrder = {
            from_location: this.$store.getters.ADDRESS_FROM,
            to_location: this.$store.getters.ADDRESS_TO,
            car_id: this.dontHaveThisModel ? null : this.order.car_model,
            number_vehicle: this.order.number_vehicle,
            comment: copy_of_order_comment,
            type_vehicle: this.$store.getters.BLOCK_TYPE,
          };

          this.$store.commit('SET_ORDER_DATA_READY', newOrder);

          // если пользователь не авторизован, просим с него имя и телефон
          if (this.$store.getters.AUTH_TYPE == 0) {
            this.$router.push('/easy-registration');
          } else {

            this.$store.dispatch('CREATE_ORDER').then(() => {
              this.$buefy.toast.open({
                message: 'Заказ успешно создан',
                type: 'is-success',
              });

              this.descriptionOfYourTransport = '';

              this.$store.dispatch('GET_ORDERS');
              this.$router.push('/activity-feed');
            }).catch((message) => {
              if (message === 'active order') {
                this.$buefy.toast.open({
                  message: 'У вас уже есть активный заказ',
                  type: 'is-danger',
                });
              } else {
                this.$buefy.toast.open({
                  message: 'Ошибка при создании заказа',
                  type: 'is-danger',
                });
              }
            });
          }
        }
      } else {
        this.$buefy.toast.open({
          message: 'Заполните обязательные поля',
          type: 'is-warning',
        });
      }
    },
  },
};

</script>

<style scope>
#orderCreatePage2 .title.is-1 {
  font-size: 4.5rem !important;
}

#orderCreatePage2 .title.is-1.thin {
  font-weight: 300;
}

#orderCreatePage2.container {
  display: flex;
  flex-direction: column;
}
</style>
